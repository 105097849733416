<template>
  <layout-dialog>
    <!-- More Fields Dialog Title -->
    <template v-slot:title>
      {{ addMoreFieldsDialogTitle }}
    </template>

    <!-- More Fields Dialog Icon -->
    <template v-slot:iconName>
      {{ iconAdd }}
    </template>

    <!-- Close Search Dialog -->
    <template #iconCrossName>
      <base-tooltip-button @click="onCancel">
        <template v-slot:icon-name>
          {{ iconClose }}
        </template>
        <template v-slot:toolTip>
          {{ commandCloseSearchDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- More Fields Dialog Context -->
    <template v-slot:default>
      <!-- field browser -->
      <field-browser v-model="selectedMoreFields" :fields="fields">
      </field-browser>

      <!-- Error display -->
      <v-sheet v-show="hasError">
        <v-divider></v-divider>

        <base-alert v-model="hasError" :dismissible="false">{{
          currentError
        }}</base-alert>
      </v-sheet>
    </template>

    <!-- More Fields Dialog Actions -->
    <template v-slot:actions>
      <base-tooltip-button
        :disabled="selectedMoreFieldCount === 0"
        @click="onOk"
      >
        {{ commandOkLabel }}
      </base-tooltip-button>

      <!-- Close More Fields Dialog -->
      <base-tooltip-button @click="onCancel">
        {{ commandCancelLabel }}
      </base-tooltip-button>
    </template>
  </layout-dialog>
</template>

<script>
import { iconAdd, iconClose } from "@/design/icon/iconConst";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "MoreFields",
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    LayoutDialog: () =>
      import("@/components/shared/core/layout/dialog/LayoutDialog"),
    FieldBrowser: () => import("@/components/field/FieldBrowser"),
    BaseAlert: () => import("@/components/shared/base/BaseAlert")
  },
  data() {
    return {
      iconAdd: iconAdd,
      iconClose: iconClose,
      selectedMoreFields: undefined
    };
  },
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    error: {
      type: String,
      default: undefined
    }
  },
  computed: {
    commandOkLabel() {
      return "Add";
    },
    commandCancelLabel() {
      return "Cancel";
    },
    commandCloseSearchDialogTooltip() {
      return "Close Add More Fields Dialog";
    },
    /**
     * selected Browsable Field Count
     * @return {number}
     */
    selectedMoreFieldCount() {
      return this.selectedMoreFields?.length ?? 0;
    },
    /**
     * add More Fields Dialog Title
     * @return {string}
     */
    addMoreFieldsDialogTitle() {
      switch (this.selectedMoreFieldCount) {
        case 0: {
          return "Select Fields";
        }
        case 1: {
          return `Add ${this.selectedMoreFieldCount} Selected Field`;
        }
        default: {
          return `Add ${this.selectedMoreFieldCount} Selected Fields`;
        }
      }
    },
    currentError() {
      return this.error;
    },
    hasError() {
      return !!this.error;
    }
  },
  methods: {
    onOk() {
      this.$emit(eventNames.dialogOK, this.selectedMoreFields);
    },
    onCancel() {
      this.$emit(eventNames.dialogCancel);
    }
  }
};
</script>

<style scoped></style>
